<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>User logs</h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button @click="syncIp">Sync IP data</b-button>
      </b-col>
    </b-row>
    <div class="d-flex ">
      <b-form-group label="Search" class="flex-grow-1">
        <b-form-input
          type="search"
          debounce="150"
          placeholder="Search"
          v-model="searchString"
          @update="doSearch"
        ></b-form-input>
      </b-form-group>
    </div>
    <vuetable
      ref="userstable"
      :api-mode="false"
      :fields="fields"
      :data="{ data: logs }"
      track-by="id"
      :data-manager="dataManager"
    >
      <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit item"
          @click="editUser(props.rowData)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove item"
          @click="deleteUser(props.rowData)"
        ></font-awesome-icon>
      </template>
    </vuetable>
  </b-overlay>
</template>
<script>
import LogsService from "@/services/LogsService";
import Vuetable from "vuetable-2";
import * as _ from "lodash";

export default {
  components: {
    Vuetable,
  },
  props: ["user"],
  data() {
    return {
      isLoading: false,
      fields: [
        { name: "email", sortField: "email" },
        { name: "ip", sortField: "ip" },
        { name: "message", sortField: "message" },
        { name: "city", sortField: "city" },
        { name: "country", sortField: "country" },
        { name: "created_at", sortField: "created_at" },
        { name: "updated_at", sortField: "updated_at" },
      ],
      searchString: null,
    };
  },
  watch: {
    user() {
      this.doFetchAll();
    },
  },
  computed: {
    logs() {
      return this.$store.getters["Logs/all"];
    },
  },
  methods: {
    doSearch() {
      this.doFetchAll();
    },
    onPaginationData(data) {
      console.log(data);
    },
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch(
        "Logs/fetchAll",
        this.user || this.searchString
      );
      this.isLoading = false;
    },
    async syncIp() {
      LogsService.sync();
      this.$root.$bvToast.toast("Sync started!", {
        variant: "success",
      });
    },
    dataManager(sortOrder, pagination) {
      if (this.logs.length < 1) return;

      let local = this.logs;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      return {
        pagination: pagination,
        data: local,
      };
    },
  },
  async created() {
    this.doFetchAll();
  },
};
</script>
<style lang="scss"></style>
